@media only screen and (max-width: 600px) {
  .item-wrapper {
    padding: 40px 0px;
    flex-direction: column;
    transform: translate(0, 20%);
  }
  .item-image-wrapper-desktop {
    display: none;
  }
  .item-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  .item-title {
    margin-bottom: -5px;
  }
  .item-title h3 {
    font-size: 1.7rem;
  }
  .item-color {
    height: 20px;
    width: 20px;
  }
  .item-footer-line {
    width: 40%;
  }
}
@media only screen and (min-width: 600px) {
  .item-wrapper {
    padding: 80px 0px;
    height: 600px;
    transform: translate(0, 40%);
  }
  .item-image-wrapper-mobile {
    display: none;
  }
  .item-title h3 {
    font-size: 2.3rem;
  }
  .item-image {
    height: 100%;
    width: 45vw;
    object-fit: cover;
  }
  .item-color {
    height: 25px;
    width: 25px;
  }
  .item-align-left {
    flex-direction: row;
  }
  .item-align-right {
    flex-direction: row-reverse;
  }
  .item-padding-right {
    padding-right: 60px;
  }
  .item-padding-left {
    padding-left: 60px;
  }
  .item-footer-line {
    width: 47%;
  }
}

@media only screen and (min-width: 1400px) {
  .item-wrapper {
    padding: 80px 50px;
  }
  .item-image {
    width: 35vw;
  }
}

.item-wrapper {
  display: flex;
  margin-top: 30px;
  border-radius: 15px;
  opacity: 0;

  visibility: hidden;
  transition: opacity 400ms ease-out, transform 300ms ease-out;
  will-change: opacity, visibility;
}
.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
  display: flex;
}

.item-order-number {
  margin-bottom: 25px;
}

.item-stats {
  font-size: 12px;
  color: rgb(158, 158, 158);
  margin-bottom: 10px;
}

.item-colors {
  display: flex;
  margin-bottom: 10px;
  margin-left: -1px;
}

.item-color {
  border-radius: 50px;
  margin-right: 7px;
}

.item-description {
  margin-top: 23px;
}

.item-footer {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.item-footer-line {
  border-bottom: 1px solid #d28f50;
  margin-bottom: 6px;
}

.item-footer-icon {
  transform: rotate(20deg);
  color: #d28f50;
}

.Marin {
  background-color: rgb(49, 67, 165);
}
.Vit {
  background-color: white;
}
.Svart {
  background-color: black;
}
.Antrazit {
  background-color: rgb(77, 77, 80);
}
.Gråmelange {
  background-color: rgb(184, 184, 184);
}
.Natur {
  background-color: rgb(240, 219, 200);
}
