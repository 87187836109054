.about-bg {
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("../../images/fabrik2.webp");
  position: sticky;
  position: -webkit-sticky;
  z-index: -1;
}

.about-card {
  text-align: center;
  margin: auto;
  background-color: rgb(255, 255, 255);

  border-radius: 15px;
  box-shadow: 0px 3px 8px 3px #6666662f;
}

@media only screen and (max-width: 600px) {
  .about-bg {
    padding: 40px 40px;
    height: 220px;
    top: 95px;
  }
  .about-card {
    padding: 40px 25px;
    margin-top: -20px;
    margin-bottom: 70px;
    width: 90vw;
  }
}
@media only screen and (min-width: 600px) {
  .about-bg {
    padding: 120px 100px;
    height: 600px;
    top: 100px;
  }
  .about-card {
    padding: 35px;
    margin-top: -150px;
    margin-bottom: 70px;
    width: 650px;
  }
}
