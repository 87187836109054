@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.1rem;
  }
  h3 {
    font-size: 1.5rem;
  }
}
@media only screen and (min-width: 600px) {
  .logo {
    transform: scale(0.3);
    margin-top: -100px;
  }
  h1 {
    font-size: 3.2rem;
  }
}

body {
  background: #eeeeee;
  font-family: "Montserrat", sans-serif;
  color: #21292e;
}

h1,
h3 {
  font-family: "Yeseva One", serif;
}
h4 {
  font-size: 1.3rem;
}

.main {
  padding: 0px 100px;
}
