@media only screen and (max-width: 600px) {
  .contact-wrapper {
    padding: 40px 25px;
  }
  .contact-anchor {
    position: absolute;
    margin-top: -130px;
  }
  .contact-link {
    font-size: 14px;
  }
}
@media only screen and (min-width: 600px) {
  .contact-wrapper {
    padding: 120px 100px;
  }
  .contact-link:hover {
    color: #849aaa;
  }
  .contact-anchor {
    position: absolute;
    margin-top: -150px;
  }
}

.contact-wrapper {
  background-color: #eeeeee;
  height: 600px;
}

.contact-link {
  color: inherit;
  transition: 0.2s all ease;
  text-decoration: underline;
}

.contact-link:hover {
}
