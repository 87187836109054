@media only screen and (min-width: 600px) {
  .toggle-btn:hover {
    cursor: pointer;
    border-bottom: 2px solid #21292e;
  }
}

.sizes1,
.sizes2 {
  display: flex;
  font-size: 12px;
}

.sizes-card {
  border: none;
  width: 200px;
  margin: 10px 0px;
}

.sizes-header {
  background-color: #eeeeee;
  border: none;
  padding: 0px;
  display: flex;
  font-size: 15px;
  font-weight: bolder;
}

.sizes-body {
  background-color: #eeeeee;
  padding: 5px 0px;
  padding-bottom: 0px;
}

.toggle-btn {
  display: flex;
  border-bottom: 2px solid transparent;
  transition: 0.4s all ease;
}

.closed .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(-0deg);
}
.closed .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(-0deg);
  opacity: 1;
}

.opened {
  opacity: 1;
}

.opened .vertical {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
}
.opened .horizontal {
  transition: all 0.5s ease-in-out;
  transform: rotate(90deg);
  opacity: 0;
}

.plus {
  margin-left: 5px;
  margin-right: 8px;
  margin-top: -1px;
}

.plus .horizontal {
  background-color: #21292e;
  width: 10px;
  height: 2px;
  margin-top: 11px;
  margin-left: -4px;
}
.plus .vertical {
  background-color: #21292e;
  width: 2px;
  height: 10px;
  margin-top: -6px;
}
