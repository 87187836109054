@media only screen and (max-width: 600px) {
  .footer-wrapper {
    padding: 40px 25px;
    font-size: 13px;
  }
  .footer-info-d {
    display: none;
  }
  .footer-signature {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 600px) {
  .footer-wrapper {
    padding: 0px 100px;
    height: 150px;
  }
  .footer-content {
    display: flex;
    justify-content: space-between;
  }
  .footer-info-m {
    display: none;
  }
}
.footer-wrapper {
  width: 100%;
  background: #eeeeee;
  margin-top: -30px;
}
.footer-content {
  margin-top: 30px;
}
