@media only screen and (max-width: 600px) {
  .products-wrapper {
    padding: 50px 30px 90px 30px;
  }
  .products-anchor {
    position: absolute;
    margin-top: -130px;
  }
}
@media only screen and (min-width: 600px) {
  .products-wrapper {
    padding: 60px 100px 80px 100px;
  }
  .products-anchor {
    position: absolute;
    margin-top: -150px;
  }
}

.products-wrapper {
  z-index: 1;
  background: #eeeeee;
}
