@media only screen and (max-width: 600px) {
  .home {
    height: 90vh;
    padding: 35vh 0px;
    background-position: bottom right;
    background-position-x: 94%;
    background-image: url("../../images/hero.webp");
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-content {
    text-align: center;
  }
  .home-title {
    font-size: 2.4rem;
  }
  .home-subtitle {
    letter-spacing: 4px;
    font-size: 0.8rem;
  }
}
@media only screen and (min-width: 600px) {
  .home {
    height: 100vh;
    padding: 0px 100px;
    padding-top: 50vh;
    background-position: bottom right;
    background-image: url("../../images/hero-large2.webp");
  }
  .home-title {
    font-size: 5rem;
    text-shadow: 2px 2px 2px rgba(36, 36, 36, 0.11);
  }
  .home-subtitle {
    letter-spacing: 5px;
    text-shadow: 2px 2px 2px rgba(36, 36, 36, 0.247);
  }
}
@media only screen and (min-width: 1450px) {
  .home-title {
    font-size: 5.5rem;
  }
  .home-subtitle {
    font-size: 1.2rem;
    letter-spacing: 5px;
  }
}

.home {
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  position: relative;
}

.home-title {
  color: white;
}

.home-subtitle {
  color: white;
}

.home-content {
  -webkit-animation: fadein 2.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2.5s; /* Firefox < 16 */
  -ms-animation: fadein 2.5s; /* Internet Explorer */
  -o-animation: fadein 2.5s; /* Opera < 12.1 */
  animation: fadein 2.5s;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
