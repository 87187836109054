.navbar-wrapper {
  width: 100vw;
  position: fixed;
  display: flex;
  transition: 0.4s all ease;
  z-index: 10;
}

.navbar-logo {
  object-fit: contain;
  position: absolute;
  transition: 0.4s all ease;
}

.nav-link {
  color: white;
  display: flex;
  align-items: center;
}
.nav-link:last-of-type {
  margin-right: 0px;
}

@media only screen and (max-width: 600px) {
  .navbar-wrapper {
    height: 95px;
    flex-direction: column;
    align-items: center;
  }
  .navbar-links {
    transition: 0.4s all ease;
    font-size: 12px;
    padding: 0px 5px;
    display: flex;
    margin-top: 70px;
    width: 100%;
    justify-content: space-between;
  }
  .navbar-links-minimize {
    margin-top: 57px;
  }
  .nav-link {
    margin-right: 5px;
    width: 95px;
    display: flex;
    justify-content: center;
  }

  .navbar-logo {
    width: 100px;
    margin-top: 10px;
  }
  .logo-minimize {
    width: 80px;
    margin-top: 10px;
  }
  .nav-link-desktop {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .navbar-wrapper {
    height: 100px;
    padding: 0px 100px;
  }
  .navbar-links {
    display: flex;
    margin-left: auto;
  }
  .nav-link {
    margin-right: 20px;
    transition: ease all 0.3s;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
  }
  .navbar-logo {
    margin-top: 20px;
    width: 200px;
  }
  .navbar-logo:hover {
    cursor: pointer;
  }
  .nav-link:hover {
    color: rgb(216, 216, 216);
    cursor: pointer;
    border-bottom: 2px solid #21292e;
    border-top: 2px solid transparent;
  }
  .nav-link-border:hover {
    border-bottom: 2px solid white;
  }
  .logo-minimize {
    width: 170px;
    margin-top: 8px;
  }
  .nav-link-mobile {
    display: none;
  }
}
